import { Component, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { I18nService } from '@common/components/i18n';
import { DialogService } from '@common/services/dialog.service';

@Component({
  selector: 'vp-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [RouterOutlet],
})
export class AppComponent {
  title = 'vp';

  constructor() {
    inject(I18nService).initLang();
    inject(DialogService).init();

    /*TODO 99456 remove after 03/01/2025
      this hack here to remove obsolete keys in local storage
      hopefully two month is enough to clear the storage
    */
    this.cleareLocalStorageFromObsoleteTableKeys();
  }

  private cleareLocalStorageFromObsoleteTableKeys() {
    for (const [key, value] of Object.entries(localStorage)) {
      if (key.length === 1 && value.startsWith('{"columns"')) {
        localStorage.removeItem(key);
      }
    }
  }
}
